import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

const Footer = () => {
  const location = useLocation();


  if (location.pathname === '/page/' || location.pathname === '/page' || location.pathname === '/page/kontakt/' || location.pathname === '/page/kontakt' || location.pathname === '/page/privatnost/' || location.pathname === '/page/privatnost' || location.pathname === '/page/uslovi/' || location.pathname === '/page/uslovi') {

    return (
      <footer className="footer rd-upload rd-load">
        <div className="container footer__container">
          <div className="footer__left">
            <h2 className="footer__title tt-3">
              <img
                src="/logo.png"
                alt=""
                style={{ filter: "invert(1)", width: 150 }}
              />
            </h2>
            <br />
            <a className="footer__link tx-17" href="mailto:info@hotelytrip.com">
              <img
                src="/mail.svg"
                data-rd-image="./img/svg/mail.svg"
                width={24}
                height={24}
                alt="mail"
              />
              info@hotelytrip.com
            </a>
          </div>
          <div className="footer__right">
            <div className="footer__right-top">
              <p className="footer__right-text tx-17">
                Planirajte svoje putovanje s nama i otkrijte najbolje smještaje uz
                jedinstvene pogodnosti. Uživajte u luksuzu, udobnosti i nezaboravnim
                iskustvima na svakom koraku.{" "}
              </p>
            </div>
            <div className="footer__right-bottom">
              <ul
                className="footer__list"
                style={{ display: "flex", gap: 30, flexWrap: "wrap" }}
              >
                <li className="footer__item">
                  <Link to="/page/privatnost">
                    <a className="footer__item-link tx-17">
                      Politika privatnosti
                    </a>
                  </Link>
                </li>
                <li className="footer__item">
                  <Link to="/page/uslovi">
                    <a className="footer__item-link tx-17">
                      Uslovi korištenja
                    </a>
                  </Link>
                </li>
                <li className="footer__item">
                  <Link to="/page/kontakt">
                    <a className="footer__item-link tx-17">
                      Kontakt
                    </a>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>

    );
  }

  return (
    <footer className="footer rd-upload rd-load">
      <div className="container footer__container">
        <div className="footer__left">
          <h2 className="footer__title tt-3">
            <img
              src="/logo.png"
              alt=""
              style={{ filter: "invert(1)", width: 150 }}
            />
          </h2>
          <br />
          <a className="footer__link tx-17" href="mailto:info@hotelytrip.com">
            <img
              src="/mail.svg"
              data-rd-image="./img/svg/mail.svg"
              width={24}
              height={24}
              alt="mail"
            />
            info@hotelytrip.com
          </a>
        </div>
        <div className="footer__right">
          <div className="footer__right-top">
            <p className="footer__right-text tx-17">
              Planirajte svoje putovanje s nama i otkrijte najbolje smještaje uz
              jedinstvene pogodnosti. Uživajte u luksuzu, udobnosti i nezaboravnim
              iskustvima na svakom koraku.{" "}
            </p>
          </div>
          <div className="footer__right-bottom">
            <ul
              className="footer__list"
              style={{ display: "flex", gap: 30, flexWrap: "wrap" }}
            >
              <li className="footer__item">
                <Link to="/privatnost">
                  <a className="footer__item-link tx-17">
                    Politika privatnosti
                  </a>
                </Link>
              </li>
              <li className="footer__item">
                <Link to="/uslovi">
                  <a className="footer__item-link tx-17">
                    Uslovi korištenja
                  </a>
                </Link>
              </li>
              <li className="footer__item">
                <Link to="/kontakt">
                  <a className="footer__item-link tx-17">
                    Kontakt
                  </a>
                </Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </footer>

  );

};

export default Footer;
