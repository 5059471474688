import React from 'react';

const PrivacyPolicy = () => {
  return (
<main>
  <section className="block-welcome rd-upload rd-load">
    <div className="block-welcome__container container">
      <div className="block-welcome__wrapper-title">
        <h1 className="block-welcome__title tt-1">Politika privatnosti</h1>
      </div>
    </div>
  </section>
  <style
    dangerouslySetInnerHTML={{
      __html:
        "\n        .konferenc__top{\n          margin-top: 50px;\n        }\n        \n        p.tx-17{\n            margin-top: 10px;\n            line-height: 1.5;\n        }\n\n        h2.tx-17{\n            line-height: 1.5;\n            font-weight: 700;\n            font-size: 32px;\n            margin-top: 30px;\n        }\n      "
    }}
  />
  <section className="konferenc" id="com">
    <div className="konferenc__container container">
      <div className="konferenc__top">
        <p className="tx-17">
          Vaša privatnost nam je izuzetno važna. Ova politika privatnosti
          objašnjava koje podatke prikupljamo, kako ih koristimo i kako štitimo
          vašu privatnost.
        </p>
        <h2 className="tx-17">Podaci koje prikupljamo</h2>
        <p className="tx-17">
          Prikupljamo sljedeće vrste podataka kada koristite našu uslugu:
        </p>
        <ul className="tx-17">
          <li className="tx-17">
            <strong>Osobni podaci:</strong> Ime, prezime, e-mail adresa,
            telefonski broj.
          </li>
          <li className="tx-17">
            <strong>Podaci o korištenju:</strong> Informacije o tome kako
            koristite našu web stranicu, uključujući IP adresu, vrstu
            preglednika i uređaja.
          </li>
        </ul>
        <h2 className="tx-17">Kako koristimo vaše podatke</h2>
        <p className="tx-17">Podatke prikupljamo u svrhu:</p>
        <ul className="tx-17">
          <li className="tx-17">Omogućavanja korištenja naših usluga.</li>
          <li className="tx-17">
            Komunikacije s vama, uključujući slanje promotivnih ponuda i
            informacija.
          </li>
          <li className="tx-17">
            Poboljšanja naših usluga i korisničkog iskustva.
          </li>
        </ul>
        <h2 className="tx-17">Zaštita vaših podataka</h2>
        <p className="tx-17">
          Poduzimamo odgovarajuće tehničke i organizacijske mjere kako bismo
          osigurali sigurnost vaših osobnih podataka od neovlaštenog pristupa,
          izmjene ili gubitka.
        </p>
        <h2 className="tx-17">Podjela podataka</h2>
        <p className="tx-17">
          Ne dijelimo vaše osobne podatke s trećim stranama, osim kada je to
          nužno za pružanje naših usluga ili kada je zakonski obavezno.
        </p>
        <h2 className="tx-17">Vaša prava</h2>
        <p className="tx-17">
          Imate pravo pristupiti svojim podacima, ispraviti ih, zatražiti
          njihovo brisanje ili ograničiti njihovu obradu. Također možete povući
          svoju suglasnost u bilo kojem trenutku.
        </p>
        <h2 className="tx-17">Kontakt</h2>
        <p className="tx-17">
          Ako imate bilo kakva pitanja o našoj politici privatnosti, molimo vas
          da nas kontaktirate putem e-mail adrese:{" "}
          <a className="tx-17" href="mailto:info@hotelytrip.com">
            info@hotelytrip.com
          </a>
          .
        </p>
      </div>
    </div>
  </section>
</main>

  );
};

export default PrivacyPolicy;
