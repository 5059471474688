import React, { useState, useEffect } from 'react';
import '../Cookiebox.css';

const Homepage = () => {
    const [showCookieBox, setShowCookieBox] = useState(true);

    const extractGclidFromURL = () => {
        const currentURL = window.location.href;
        if (currentURL.includes('?')) {
            const queryString = currentURL.split('?')[1];
            const params = new URLSearchParams(queryString);
            return params.get('gclid');
        }
        return null;
    };

    const finalGclid = extractGclidFromURL();

    const handleAcceptCookies = () => {
        if (finalGclid !== null) {
            window.location.href = `/page/?gclid=${finalGclid}`;
        } else {
            window.location.href = "/page/";
        }
    };

    const handleDeclineCookies = () => {
        window.location.href = "/privatnost/";
    };

    useEffect(() => {
    }, []);

    return (
        <div>

            {showCookieBox && (
                <div className="cookiebox">
                    <div className="cookiebox-container">
                        <div className="cookiebox-header">
                            <img src="/cookies.svg" alt="Cookies" />
                            Ova stranica koristi kolačiće
                        </div>
                        <div className="cookiebox-body">
                            <p>
                                Koristimo kolačiće (cookies) kako bismo vam osigurali najbolje
                                iskustvo na našoj web stranici. Kolačići nam pomažu u prilagodbi
                                sadržaja, pružanju značajki društvenih medija te analizi prometa.
                                <br />
                                <br />
                                Klikom na "Prihvaćam" pristajete na korištenje kolačića.
                            </p>
                            <div className="cookiebox-buttons">
                                <button id="btnYes" onClick={handleAcceptCookies}>
                                    Prihvaćam
                                </button>
                                <button id="btnNo" onClick={handleDeclineCookies}>
                                    Odbijam
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            )}

            <main>
                <section className="block-welcome rd-upload rd-load">
                    <div className="block-welcome__container container">
                        <div className="block-welcome__wrapper-title">
                            <h1 className="block-welcome__title tt-1">
                                Otkrijte svijet luksuznih hotela u Hrvatskoj
                            </h1>
                            <p className="block-welcome__text tx-17">
                                Planirajte svoje savršeno putovanje uz Hotely Trip – vaš vodič kroz
                                najbolje hotelske destinacije diljem svijeta. Uživajte u jedinstvenim
                                smještajima, luksuznim pogodnostima i nezaboravnim iskustvima koja će
                                vaša putovanja podići na novu razinu. Krenite u avanturu iz snova već
                                danas!
                            </p>
                        </div>
                    </div>
                </section>
                <section className="about" id="rest">
                    <div className="about__container container">
                        <div className="about__left">
                            <h2 className="about__title tt-2">O nama</h2>
                            <p className="about__text tx-17">
                                U Hotely Tripu pažljivo biramo najbolje svjetske hotelske destinacije
                                i pružamo vam cjelovit vodič do vrhunskih smještaja, uzbudljivih
                                aktivnosti i jedinstvenih pogodnosti. Naša je misija postati vaš
                                pouzdani izvor informacija i pomoći vam u planiranju savršenog odmora,
                                gdje možete uživati u udobnosti, istraživanju i luksuzu.
                                <br />
                                <br />
                                Krenite na putovanje ispunjeno luksuzom, avanturom i opuštanjem s
                                Hotely Tripom. Neka budemo vaš vjerni suputnik dok otkrivate svijet
                                hotelskih destinacija i stvarate uspomene koje će trajati cijeli
                                život. <br />
                                <br />
                                Bez obzira jeste li iskusni putnik ili tražite bijeg iz svakodnevice,
                                mi smo tu da vaša putovanja učinimo nezaboravnima. <br />
                                <br />
                                Počnite planirati svoju sljedeću avanturu s Hotely Tripom već danas i
                                dopustite nam da budemo dio vaše jedinstvene turističke priče.
                            </p>
                        </div>
                        <div className="about__img rd-upload rd-load">
                            <img src="/img02.webp" alt=" Restorāns" />
                        </div>
                    </div>
                </section>
                <section className="konferenc" id="com">
                    <div className="konferenc__container container">
                        <div className="konferenc__top">
                            <h2 className="konferenc__title tt-2">
                                Lista najboljih kazino hotela u Hrvatskoj
                            </h2>
                        </div>
                        <ul className="konferenc__list">
                            <li className="konferenc__item img1">
                                <a href="https://www.booking.com/hotel/hr/admiral-zagreb.hr.html">
                                    <h3 className="konferenc__title2 tx-22">Admiral Hotel</h3>
                                </a>
                                <p className="konferenc__text tx-17">
                                    140 Rudeška cesta, 10000 Zagreb, Hrvatska
                                </p>
                            </li>
                            <li className="konferenc__item img2">
                                <a href="https://www.booking.com/hotel/hr/casino-mulino.hr.html">
                                    <h3 className="konferenc__title2 tx-22">
                                        Mulino Luxury Boutique Hotel
                                    </h3>
                                </a>
                                <p className="konferenc__text tx-17">
                                    Skrile 75a, 52460 Buje, Hrvatska
                                </p>
                            </li>
                            <li className="konferenc__item img3">
                                <a href="https://www.booking.com/hotel/hr/paris.hr.html">
                                    <h3 className="konferenc__title2 tx-22">Hotel Paris</h3>
                                </a>
                                <p className="konferenc__text tx-17">
                                    Ulica Vladimira Nazora 1, 51410 Opatija, Hrvatska{" "}
                                </p>
                            </li>
                            <li className="konferenc__item img4">
                                <a href="https://www.booking.com/hotel/hr/atrium.hr.html">
                                    <h3 className="konferenc__title2 tx-22">Hotel Atrium</h3>
                                </a>
                                <p className="konferenc__text tx-17">
                                    Domovinskog rata 49A, 21000 Split, Hrvatska
                                </p>
                            </li>
                            <li className="konferenc__item img5">
                                <a href="https://www.booking.com/hotel/hr/solkoralj.hr.html">
                                    <h3 className="konferenc__title2 tx-22">
                                        Hotel Coral Plava Laguna
                                    </h3>
                                </a>
                                <p className="konferenc__text tx-17">
                                    Katoro 20, 52470 Umag, Hrvatska{" "}
                                </p>
                            </li>
                            <li className="konferenc__item img6">
                                <a href="https://www.booking.com/hotel/hr/westinzagreb.hr.html">
                                    <h3 className="konferenc__title2 tx-22">The Westin Zagreb</h3>
                                </a>
                                <p className="konferenc__text tx-17">
                                    Kršnjavoga 1, Donji Grad, 10000 Zagreb, Hrvatska
                                </p>
                            </li>
                        </ul>
                    </div>
                </section>
                <section className="play" id="play">
                    <div className="play__container container">
                        <div className="play__cards">
                            <div className="play__left">
                                <h2 className="play__title tt-1">Otkrijte Hrvatsku uz Hotely Trip</h2>
                                <p className="play__text tx-17">
                                    Hrvatska je zemlja bogate povijesti, kulture i predivnih destinacija
                                    koje nude nezaboravna iskustva. Hotely Trip vam omogućava da
                                    istražite sve što Hrvatska nudi, od vrhunskih hotela do jedinstvenih
                                    lokalnih doživljaja. Bilo da tražite luksuzni smještaj za poslovno
                                    putovanje ili savršeno mjesto za opuštanje, naša pažljivo odabrana
                                    ponuda hotela zadovoljit će sve vaše potrebe. Prepustite
                                    organizaciju nama i uživajte u svemu što Hrvatska ima za ponuditi,
                                    uz udobnost i kvalitetu koju vam osigurava Hotely Trip.
                                </p>
                            </div>
                            <div className="play__image rd-upload rd-load">
                                <img src="/img03.webp" alt="Spēļu automāti" />
                            </div>
                        </div>
                    </div>
                </section>
            </main>
        </div>
    );
};

export default Homepage;
