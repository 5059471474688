import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';


const Header = () => {
  const [isMenuActive, setIsMenuActive] = useState(false);
  const location = useLocation();

  const handleBurgerClick = () => {
    setIsMenuActive(true);
    document.body.style.overflow = 'hidden';
  };

  const handleMenuClose = () => {
    setIsMenuActive(false);
    document.body.style.overflow = '';
  };

  if (location.pathname === '/page/' || location.pathname === '/page' || location.pathname === '/page/kontakt/' || location.pathname === '/page/kontakt' || location.pathname === '/page/privatnost/' || location.pathname === '/page/privatnost' || location.pathname === '/page/uslovi/' || location.pathname === '/page/uslovi') {
    return (
        <header className="header rd-upload rd-load">
        <div className="header__container container">
          <ul className="header__list">
            <li className="header__item">
              <Link to="/page">
                <img src="/logo.png" alt="Logo" />
              </Link>
            </li>
          </ul>
          <button className="burger" onClick={handleBurgerClick}>
            <span />
            <span />
            <span />
          </button>
          <div className={`header__menu ${isMenuActive ? 'active' : ''}`}>
            <nav className="nav">
              <ul className="nav__list">
                <li className="nav__item">
                  <Link className="nav__link tx-20" to="/page" onClick={handleMenuClose}>
                    Početna
                  </Link>
                </li>
                <li className="nav__item">
                  <a className="nav__link tx-20" href="/page/#rest" onClick={handleMenuClose}>
                    O nama
                  </a>
                </li>
                <li className="nav__item">
                  <Link className="nav__link tx-20" to="/page/kontakt" onClick={handleMenuClose}>
                    Kontakt
                  </Link>
                </li>
              </ul>
            </nav>
            <button className="header__exit-menu" onClick={handleMenuClose}>
            </button>
          </div>
        </div>
      </header>
      );
  }

  return (
    <header className="header rd-upload rd-load">
      <div className="header__container container">
        <ul className="header__list">
          <li className="header__item">
            <Link to="/">
              <img src="/logo.png" alt="Logo" />
            </Link>
          </li>
        </ul>
        <button className="burger" onClick={handleBurgerClick}>
          <span />
          <span />
          <span />
        </button>
        <div className={`header__menu ${isMenuActive ? 'active' : ''}`}>
          <nav className="nav">
            <ul className="nav__list">
              <li className="nav__item">
                <Link className="nav__link tx-20" to="/" onClick={handleMenuClose}>
                  Početna
                </Link>
              </li>
              <li className="nav__item">
                <a className="nav__link tx-20" href="/#rest" onClick={handleMenuClose}>
                  O nama
                </a>
              </li>
              <li className="nav__item">
                <Link className="nav__link tx-20" to="/kontakt" onClick={handleMenuClose}>
                  Kontakt
                </Link>
              </li>
            </ul>
          </nav>
          <button className="header__exit-menu" onClick={handleMenuClose}>
          </button>
        </div>
      </div>
    </header>
  );
};

export default Header;
