import React from 'react';

const Terms = () => {
  return (
<main>
  <section className="block-welcome rd-upload rd-load">
    <div className="block-welcome__container container">
      <div className="block-welcome__wrapper-title">
        <h1 className="block-welcome__title tt-1">Uslovi korištenja</h1>
      </div>
    </div>
  </section>
  <style
    dangerouslySetInnerHTML={{
      __html:
        "\n        .konferenc__top{\n          margin-top: 50px;\n        }\n        \n        p.tx-17{\n            margin-top: 10px;\n            line-height: 1.5;\n        }\n\n        h2.tx-17{\n            line-height: 1.5;\n            font-weight: 700;\n            font-size: 32px;\n            margin-top: 30px;\n        }\n      "
    }}
  />
  <section className="konferenc" id="com">
    <div className="konferenc__container container">
      <div className="konferenc__top">
        <p className="tx-17">
          Dobrodošli na web stranicu Hotely Trip. Korištenjem ove stranice
          prihvaćate sljedeće uslove korištenja. Molimo vas da pažljivo
          pročitate ove uslove prije korištenja naše web stranice.
        </p>
        <h2 className="tx-17">Prihvaćanje uslova</h2>
        <p className="tx-17">
          Korištenjem ove web stranice potvrđujete da ste pročitali, razumjeli i
          prihvatili ove uslove korištenja. Ako se ne slažete s bilo kojim
          dijelom ovih uslova, molimo vas da ne koristite našu web stranicu.
        </p>
        <h2 className="tx-17">Korištenje web stranice</h2>
        <p className="tx-17">
          Sadržaj na ovoj stranici namijenjen je isključivo za vašu osobnu
          upotrebu. Nije dozvoljeno kopiranje, distribucija, modifikacija ili
          objavljivanje sadržaja bez prethodnog pismenog odobrenja.
        </p>
        <h2 className="tx-17">Ograničenje odgovornosti</h2>
        <p className="tx-17">
          Hotely Trip neće biti odgovoran za bilo kakve štete koje mogu nastati
          korištenjem ove web stranice, uključujući, ali ne ograničavajući se
          na, direktne, indirektne, slučajne ili posljedične štete.
        </p>
        <h2 className="tx-17">Zaštita privatnosti</h2>
        <p className="tx-17">
          Korištenjem naše web stranice slažete se s našom{" "}
          <a href="/privatnost.html" className="tx-17">
            Politikom Privatnosti
          </a>{" "}
          koja opisuje kako prikupljamo, koristimo i štitimo vaše osobne
          podatke.
        </p>
        <h2 className="tx-17">Izmjene uslova</h2>
        <p className="tx-17">
          Hotely Trip zadržava pravo izmjene ovih uslova korištenja u bilo kojem
          trenutku. Promjene će stupiti na snagu nakon što budu objavljene na
          ovoj stranici. Molimo vas da redovno provjeravate uslove korištenja
          kako biste bili informisani o eventualnim promjenama.
        </p>
        <h2 className="tx-17">Kontakt</h2>
        <p className="tx-17">
          Ako imate bilo kakva pitanja o ovim uslovima korištenja, molimo vas da
          nas kontaktirate putem e-mail adrese:{" "}
          <a className="tx-17" href="mailto:info@hotelytrip.com">
            info@hotelytrip.com
          </a>
          .
        </p>
      </div>
    </div>
  </section>
</main>

  );
};

export default Terms;
