import React, { useState } from 'react';
import '../Contact.css';

const Contact = () => {
  const [formSubmitted, setFormSubmitted] = useState(false); 

  const submitForm = (event) => {
    event.preventDefault();
    setFormSubmitted(true); 
  };

  return (
    <main>
      <section className="block-welcome rd-upload rd-load">
        <div className="block-welcome__container container">
          <div className="block-welcome__wrapper-title">
            <h1 className="block-welcome__title tt-1">Kontakt</h1>
          </div>
        </div>
      </section>

      <section className="konferenc" id="com">
        <div className="konferenc__container container">
          <div className="konferenc__top">
            {!formSubmitted ? (
              <form id="contactForm" onSubmit={submitForm}>
                <label htmlFor="name">Ime:</label>
                <input type="text" id="name" name="name" placeholder="Unesite svoje ime" required />
    
                <label htmlFor="email">Email:</label>
                <input type="email" id="email" name="email" placeholder="Unesite svoju email adresu" required />
    
                <label htmlFor="message">Poruka:</label>
                <textarea id="message" name="message" placeholder="Unesite svoju poruku" required></textarea>
    
                <button type="submit">Pošalji</button>
              </form>
            ) : (
              <p id="thanks-message" className="thanks-message">
                Hvala vam što ste nas kontaktirali!
              </p>
            )}
          </div>
        </div>
      </section>
    </main>
  );
};

export default Contact;
